import Moment from 'moment';
import { LoadableContent } from 'app/components/LoadableContent';
import { EmptyState } from 'app/components/EmptyState';
import { List } from 'app/components/List';
import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { messages } from './messages';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { Stats, DailySummaryStats, DailySummaryData } from 'types/API/stats';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { request } from 'utils/request';
import { useInstituteSelector } from 'app/atoms/selectors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { InlineDatePicker } from './components/StatsContent/components/inlineDatePicker';
import { getEnv } from 'utils/common/env';

registerLocale('fr', fr);
const API_URL = getEnv('API_URL');

export const DailySummary = props => {
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  var date = new Date();
  let firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1, 8);
  let lastDay = new Date();

  const { institute } = useInstituteSelector();

  const [startDate, setStartDate] = useState(firstDayOfTheMonth);
  const [endDate, setEndDate] = useState(lastDay);

  const mainTill =
    props.institute.caisses.length > 0
      ? props.institute.caisses.reduce(function (prev, curr) {
          // get the till with the lowest number aka the main till
          return prev.number < curr.number ? prev : curr;
        })
      : { id: -1, number: -1 };

  const tillId = mainTill.id;
  const tillNumber = mainTill.number;

  let keyArray = [
    'dateday',
    'caisse_ttc',
    'ca_cb',
    'ca_esp',
    'ca_cheque',
    'ca_presta_abo',
    'ca_presta_antip',
    'ca_presta_passage',
    'soins',
    'nbr_ticket',
    'avg_ticket',
    /*'antip_renouv', 'antip_new', 'prelev_new',*/ 

    'nbEsthet',
    'concept_epil',
    'concept_skin',
    'nail',
    'vente',
    // Newly created statistics
    'epil',
    'ipl',
    //'body',
    //'cures_total',
    'cures_ipl',
    //'cures_epil',
    'cures_soin',
    //'cures_nail',
    //'cures_body',
    'promotions_total',
    'number_of_free_products',
    //'number_of_free_cures',
    'number_of_free_promotions',
    'tips'
  ];
  let keyTitle = [
    'Chiffres',
    'CA Total',
    'CB',
    'Espèces',
    'Chèques',
    'CA Prélev',
    'CA Antip',
    'CA Passage',
    'CA Soins',
    'Nb Ticket',
    'Ticket moyen abonnées',
    /*, "Nb Antip Réab", "Nb Antip New.", "Nb Prélèv New"*/

    'Moyenne Esthete',
    'Concept Epil',
    'Concept Skin',
    'CA Nail',
    'CA Ventes',
    // Newly created statistics
    'CA Epil',
    'CA IPL',
    //'CA Body',
    //'CA Cures Total',
    'CA Cures IPL',
    //'CA Cures Epil',
    'CA Cures Soin',
    //'CA Cures Nail',
    //'CA Cures Body',
    'CA Total Promotions',
    'Nb Produits gratuits',
    //'Nb Cures gratuites',
    'Nb Promo à 0€',
    'Pourboires'

  ];

  const { t } = useTranslation();

  const [totaux, setTotaux] = useState<DailySummaryData>({
    dateday: '1',
    caisse_ttc: 0,
    ca_cb: 0,
    ca_esp: 0,
    ca_cheque: 0,
    ca_presta_abo: 0,
    ca_presta_antip: 0,
    ca_presta_passage: 0,
    ca_antip: 0,
    total_abo: 0,
    total_antip: 0,
    total_contrat: 0,
    ca_prelev: 0,
    ca_chq: 0,
    total_ce: 0,
    avg_ticket: 0,
    total_frais: 0,
    vente_abo_jeune: 0,
    ca_prelev_jeune: 0,
    antip_renouv: 0,
    antip_new: 0,
    new_prelev: 0,
    nbr_ticket: 0,
    nbr_soin: 0,
    soins: 0,
    nbr_uv: 0,
    prelev_new: 0,
    vente: 0,
    nbEsthet: 0,
    concept_epil: 0,
    concept_skin: 0,
    nail: 0,
      // Newly created statistics
    ipl: 0,
    epil: 0,
    body: 0,
    cures_total: 0,
    cures_ipl: 0,
    cures_epil: 0,
    cures_soin: 0,
    cures_nail: 0,
    cures_body: 0,
    promotions_total: 0,
    tips: 0,
    number_of_free_products: 0,
    number_of_free_cures: 0,
    number_of_free_promotions: 0
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState<DailySummaryStats>();

  const loadData = async (start, end) => {
    setStartDate(start);
    setEndDate(end);

    if (!loading) {
      setLoading(true);
      fetchDailySummary({ caisseId: tillId, start: start, end: end })
        .then(data => {
          setLoading(false);
          setData(data);
        })
        .catch(error => {
          setLoading(false);
          setError(error);
          console.log(error);
        });
    }
  };

  const downloadLastMonthCsvFile = () => {
    const element = document.createElement('a');
    element.href = `${API_URL}/csv/daily/stats_${institute.name}_caisse${tillNumber}.csv`;
    element.click();
  };

  const downloadLastYearCsvFile = () => {
    const element = document.createElement('a');
    let lastYear = new Date().getFullYear() - 1;
    element.href = `${API_URL}/csv/daily/stats_${institute.name}_${lastYear}.csv`;
    element.click();
  };

  useEffect(() => {
    calculateTotaux();
    return () => {};
  }, [data]);

  useEffect(() => {
    let data: DailySummaryStats = { chiffre: [] };
    setData(data);
    return () => {};
  }, [institute]);

  const calculateTotaux = () => {
    if (data) {
      var palmade: DailySummaryData = {
        dateday: '',
        caisse_ttc: 0,
        ca_cb: 0,
        ca_esp: 0,
        ca_cheque: 0,
        ca_presta_abo: 0,
        ca_presta_antip: 0,
        ca_presta_passage: 0,
        ca_antip: 0,
        total_abo: 0,
        total_antip: 0,
        total_contrat: 0,
        ca_prelev: 0,
        ca_chq: 0,
        total_ce: 0,
        avg_ticket: 0,
        total_frais: 0,
        vente_abo_jeune: 0,
        ca_prelev_jeune: 0,
        antip_renouv: 0,
        antip_new: 0,
        new_prelev: 0,
        nbr_ticket: 0,
        nbr_soin: 0,
        soins: 0,
        nbr_uv: 0,
        prelev_new: 0,
        vente: 0,
        nbEsthet: 0,
        concept_epil: 0,
        concept_skin: 0,
        nail: 0,
        // Newly created statistics
        ipl: 0,
        epil: 0,
        body: 0,
        cures_total: 0,
        cures_ipl: 0,
        cures_epil: 0,
        cures_soin: 0,
        cures_nail: 0,
        cures_body: 0,
        promotions_total: 0,
        tips: 0,
        number_of_free_products: 0,
        number_of_free_cures: 0,
        number_of_free_promotions: 0
      };

      // filter the displayed data
      let filteredData = data.chiffre.filter(data => {
        let currentDate = data.dateday;
        var startDateMinusOneDay = new Date(startDate);
        startDateMinusOneDay.setDate(startDateMinusOneDay.getDate() - 1);
        let startDateString = startDateMinusOneDay.toISOString();
        let endDateString = endDate.toISOString();
        return currentDate > startDateString && currentDate < endDateString;
      });

      let nbDayWithEsthet = 0;
      let nbDayWithTicket = 0;

      for (var i = 0; i < filteredData.length; i++) {
        let values = filteredData[i];
        Object.keys(values).forEach((elt, index) => {
          if (elt != 'dateday') {
            palmade[elt] += values[elt];

            if (elt == 'nbEsthet' && values[elt] > 0) {
              nbDayWithEsthet++;
            }

            if (elt == 'avg_ticket' && values[elt] > 0) {
              nbDayWithTicket++;
            }
          }
        });
      }

      palmade.nbEsthet = palmade.nbEsthet / nbDayWithEsthet;
      palmade.avg_ticket = palmade.avg_ticket / nbDayWithTicket;

      setTotaux(palmade);
    }
  };

  useEffect(() => {
    calculateTotaux();
    return () => {};
  }, [startDate, endDate]);

  function StatsHeaderMUI() {
    return (
      <>
        <TableHead>
          <TableRow>
            {keyTitle.map((key, index) => {
              /*if (index == 0) {
                return (
                  <TableCell align="center" className={classes.sticky}>
                    <b className={classes.colTitle}>{key}</b>
                  </TableCell>
                );
              } else {*/
                return (
                  <TableCell align="center" className={classes.colTitle}>
                    {key}
                  </TableCell>
                );
              //}
            })}
          </TableRow>
        </TableHead>
      </>
    );
  }

  function StatsCellMUI(props) {
    return (
      <>
        <StyledTableRow key={props.stats.dateday}>
          {keyArray.map((key, index) => {
            if (index == 0) {
              if (props.date) {
                return (
                  <TableCell component="th" scope="row" align="center">
                    <DateLabel
                      className="date-label colTitle"
                      dangerouslySetInnerHTML={{
                        __html: t(messages.dailyDateFormat(), {
                          date: new Date(props.stats.dateday),
                        }),
                      }}
                    />
                  </TableCell>
                );
              } else {
                return (
                  <TableCell component="th" scope="row" align="center">
                    <b className={classes.colTitle}>Cumulés</b>
                  </TableCell>
                );
              }
            } else {
              if (props.stats[key]) {
                if (Number.isInteger(props.stats[key])) {
                  return (
                    <TableCell className={classes.ellipsis} align="center">
                      {props.stats[key]}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell className={classes.ellipsis} align="center">
                      {props.stats[key].toFixed(2)}
                    </TableCell>
                  );
                }
              } else {
                return (
                  <TableCell className={classes.ellipsis} align="center">
                    0
                  </TableCell>
                );
              }
            }
          })}
        </StyledTableRow>
      </>
    );
  }

  function StatsBody02() {
    return (
      <>
        <br />
        <TableContainer component={Paper} className={classes.tableWidth}>
          <Table aria-label="simple table" className={classes.table}>
            <StatsHeaderMUI />

            <TableBody>
              <StatsCellMUI stats={totaux} date={false}></StatsCellMUI>
              {data &&
                data.chiffre
                  .filter(data => {
                    var total: Stats.Values = {};
                    total += data.caisse_ttc;
                    let currentDate = data.dateday;
                    var startDateMinusOneDay = new Date(startDate);
                    startDateMinusOneDay.setDate(
                      startDateMinusOneDay.getDate() - 1,
                    );
                    let startDateString = startDateMinusOneDay.toISOString();
                    let endDateString = endDate.toISOString();
                    return (
                      currentDate > startDateString &&
                      currentDate < endDateString
                    );
                  })
                  .map(stat => (
                    <StatsCellMUI stats={stat} date={true}></StatsCellMUI>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  let fontSize = 10;

  const useStyles = makeStyles({
    ellipsis: {
      minWidth: 50, // percentage also works
      maxWidth: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignItems: 'center',
      fontSize: fontSize,
      margin: 0,
      paddingLeft: 3,
      paddingRight: 3
    },
    table: {
      // margin: "auto",
      '& .MuiTableCell-root': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        margin: 0,
        fontSize: fontSize,
      },
    },
    sticky: {
      position: 'sticky',
      minWidth: 120,
      left: 0,
      background: 'white',
      boxShadow: '5px 2px 5px grey',
      fontSize: fontSize,
    },
    colTitle: {
      fontSize: fontSize,
      margin: 0,
      padding: 3,
      maxWidth: 200,
      wordBreak: 'normal'
    },
    tableWidth: {
      width: '100%',
      margin: 0,
      padding: 0,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    loadData(startDate, endDate);
  }, []);

  return (
    <>
      <div>
        <div>
          <p>
            Institut: {institute.name} (
            {Moment(startDate).format('DD MMMM yyyy')} -{' '}
            {Moment(endDate).format('DD MMMM yyyy')}){' '}
          </p>
          <InlineDatePicker
            start={startDate}
            end={endDate}
            max={Date()}
            action={loadData}
          ></InlineDatePicker>
        </div>

        <BodyMinuteButtons>
          {tillNumber > 0 && (
            <BodyMinuteButton
              type="submit"
              value="Télécharger les chiffres du mois dernier"
              onClick={downloadLastMonthCsvFile}
            />
          )}
          <BodyMinuteButton
            type="submit"
            value="Télécharger les chiffres de l'annèe derniere"
            onClick={downloadLastYearCsvFile}
          />
        </BodyMinuteButtons>
      </div>

      <LoadableContent
        error={error}
        isLoading={loading}
        isEmpty={data === undefined}
        emptyNodeRenderer={() => <EmptyState label="Liste vide" />}
      >
        <StatsBody02 />
      </LoadableContent>
    </>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#e462a199',
  },
}));

export const fetchDailySummary = async ({
  caisseId,
  start,
  end,
}: {
  caisseId: number;
  start: Date;
  end: Date;
}) => {
  let oneOClockStartDate = new Date(start.setHours(7, 0, 0, 0));
  let elevenOClockEndDate = new Date(end.setHours(23, 0, 0, 0));
  const response = request<DailySummaryStats>(`api/stat/caisse/${caisseId}`, {
    method: 'POST',
    data: {
      caisseId: caisseId,
      startDate: oneOClockStartDate,
      endDate: elevenOClockEndDate,
    },
  });
  return response;
};

const StatsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  /* border-radius: 20px; */
  padding: 10px;
  margin: 10px;
  margin-bottom: 30px;
`;

const DateLabel = styled.span`
  font-family: 'Gabriel Sans Cond';
  font-weight: 500;
  font-size: fontSize;
  color: ${p => p.theme.primary};
  > span {
    color: ${p => p.theme.text.title};
  }
`;

const StatsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  :nth-child(even) {
    background: #e462a199;
  }
`;

const EvenRowWrapper = styled.span`
  :nth-child(even) {
    background: #e462a199;
  }
`;

const StatsItem = styled.span`
  text-align: center;
  width: 7%;
  min-width: 2%;
  font-size: 0.8rem;
  border: 1px solid grey;
`;

const StatsItemWide = styled.span`
  width: 10%;
`;

const BodyMinuteButton = styled.input`
  background: #e462a1;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin: 30px;
`;

const BodyMinuteButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 95%;
`;